import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="145" height="145" viewBox="0 0 145 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M122.344 18.125V97.4219C122.348 101.974 120.831 106.396 118.034 109.987C115.237 113.577 111.319 116.13 106.905 117.239C102.49 118.348 97.8316 117.949 93.6696 116.106C89.5076 114.263 86.0809 111.082 83.9343 107.069C81.7876 103.055 81.0441 98.4389 81.8221 93.9542C82.6 89.4695 84.8546 85.3734 88.2276 82.3172C91.6006 79.2609 95.8984 77.4197 100.438 77.0864C104.977 76.753 109.498 77.9465 113.281 80.4773V23.9284L49.8438 39.7878V115.547C49.8484 120.099 48.3314 124.521 45.534 128.112C42.7366 131.702 38.8193 134.255 34.4047 135.364C29.9901 136.473 25.3316 136.074 21.1696 134.231C17.0076 132.388 13.5809 129.207 11.4343 125.194C9.2876 121.18 8.54415 116.564 9.32206 112.079C10.1 107.594 12.3546 103.498 15.7276 100.442C19.1006 97.3859 23.3984 95.5447 27.9379 95.2114C32.4773 94.878 36.998 96.0715 40.7813 98.6023V36.25C40.7813 35.2395 41.1191 34.2579 41.7411 33.4614C42.363 32.6649 43.2333 32.0992 44.2137 31.8541L116.714 13.7291C117.382 13.5621 118.079 13.5496 118.753 13.6925C119.426 13.8353 120.058 14.1298 120.601 14.5536C121.144 14.9773 121.583 15.5192 121.885 16.1381C122.187 16.7569 122.344 17.4364 122.344 18.125Z" fill="white" fill-opacity="0.15"/>
    </svg>
  </SVGUniqueID>
);
