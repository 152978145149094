import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C15.5225 0 20 4.4775 20 10C20 15.5225 15.5225 20 10 20C4.4775 20 0 15.5225 0 10C0 4.4775 4.4775 0 10 0ZM10 0.833333C4.9375 0.833333 0.833333 4.9375 0.833333 10C0.833333 15.0625 4.9375 19.1667 10 19.1667C15.0625 19.1667 19.1667 15.0625 19.1667 10C19.1667 4.9375 15.0625 0.833333 10 0.833333ZM14.1667 4.925V13C14.1667 14.5183 12.9583 14.8433 12.2 14.8433C11.4467 14.8433 10.8258 14.2633 10.8258 13.5242C10.8258 12.6342 11.495 12.2617 12.3442 12.0892L13.1108 11.9167C13.4208 11.8542 13.5175 11.6267 13.5175 11.385L13.525 7.51917C13.525 7.25 13.4 7.15333 13.0967 7.2225L8.67167 8.12C8.41667 8.16167 8.34083 8.23667 8.34083 8.54083V14.16C8.34083 15.685 7.1125 16.0375 6.3525 16.0375C5.58667 16.0375 5 15.45 5 14.7183C5 13.8142 5.67667 13.4417 6.48333 13.2483L7.30583 13.0825C7.56083 13.0208 7.66417 12.8208 7.66417 12.5925V6.00833C7.66417 5.67 7.90583 5.525 8.1825 5.47L13.4008 4.41417C13.8767 4.3175 14.1667 4.3725 14.1667 4.925Z" fill="white"/>
    </svg>
  </SVGUniqueID>
);
