import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.11958 0C4.00908 0.446639 0 4.7447 0 9.98084C0 15.5143 4.47716 20 10 20C15.5229 20 20 15.5143 20 9.98084C20 8.8409 19.81 7.74541 19.4599 6.72443L16.7033 8.93101C16.756 9.2731 16.7834 9.62373 16.7834 9.98084C16.7834 13.748 13.7379 16.7863 10 16.7863C6.26216 16.7863 3.21659 13.748 3.21659 9.98084C3.21659 6.51438 5.79547 3.66496 9.11958 3.23227V0Z" fill="white"/>
      <path d="M11.858 0C14.017 0.423181 15.9254 1.57157 17.3333 3.18879L15.6293 6.21843C15.0534 5.29507 14.2677 4.52288 13.3412 3.97274V10.0345H13.339C13.274 11.8677 11.8057 13.3333 10.004 13.3333C8.1608 13.3333 6.66663 11.7996 6.66663 9.9076C6.66663 8.0156 8.1608 6.48187 10.004 6.48187C10.69 6.48187 11.3277 6.69437 11.858 7.05875V0Z" fill="white"/>
    </svg>
  </SVGUniqueID>
);
