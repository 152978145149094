import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 1.55829C5.33777 1.55829 1.55829 5.33777 1.55829 10C1.55829 14.6622 5.33777 18.4417 10 18.4417C14.6622 18.4417 18.4417 14.6622 18.4417 10C18.4417 5.33777 14.6622 1.55829 10 1.55829ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.46055 13.3571H6.85706V6.64285H9.46055H11.1912C12.0591 6.64285 12.7778 7.31701 12.8333 8.18319L12.857 8.55324C12.8958 9.15952 12.4358 9.68246 11.8295 9.72128C11.8061 9.72278 11.7827 9.72353 11.7592 9.72353H10.6099H11.8005C12.5816 9.72353 13.2148 10.3567 13.2148 11.1378C13.2148 11.1736 13.2134 11.2093 13.2107 11.245L13.1883 11.5403C13.1104 12.5653 12.2561 13.3571 11.2282 13.3571H9.46055ZM9.38166 10.6714V11.5403H10.1306C10.3706 11.5403 10.5651 11.3458 10.5651 11.1059C10.5651 10.8659 10.3706 10.6714 10.1306 10.6714H9.38166ZM9.38166 8.61764V9.24958H10.0913C10.2658 9.24958 10.4073 9.10811 10.4073 8.93361C10.4073 8.75911 10.2658 8.61764 10.0913 8.61764H9.38166Z" fill="white"/>
    </svg>
  </SVGUniqueID>
);
