import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.475 12.025C20.7107 12.025 20.9383 12.1103 21.1159 12.2652C21.2934 12.4201 21.4089 12.634 21.4409 12.8674L21.45 13V19.825C21.4501 20.7354 21.1028 21.6115 20.4791 22.2746C19.8553 22.9377 19.0021 23.3379 18.0934 23.3935L17.875 23.4H8.12505C7.21468 23.4001 6.33858 23.0528 5.67546 22.429C5.01234 21.8053 4.61216 20.9521 4.55655 20.0434L4.55005 19.825V13C4.55012 12.753 4.64397 12.5152 4.81261 12.3347C4.98126 12.1542 5.21214 12.0444 5.45859 12.0276C5.70505 12.0107 5.94871 12.0881 6.14034 12.244C6.33197 12.3999 6.45728 12.6227 6.49095 12.8674L6.50005 13V19.825C6.50005 20.6661 7.13965 21.359 7.95865 21.4422L8.12505 21.45H17.875C18.2771 21.4499 18.6649 21.3007 18.9633 21.0313C19.2618 20.7619 19.4498 20.3914 19.4909 19.9914L19.5 19.825V13C19.5 12.7414 19.6028 12.4934 19.7856 12.3106C19.9685 12.1277 20.2165 12.025 20.475 12.025ZM6.79515 8.40061L12.311 2.88601C12.4761 2.72089 12.695 2.62049 12.9278 2.60307C13.1607 2.58566 13.392 2.65238 13.5798 2.79111L13.689 2.88601L19.2049 8.40061C19.3816 8.57409 19.4857 8.80829 19.496 9.05568C19.5063 9.30308 19.4221 9.54513 19.2605 9.73272C19.0989 9.92031 18.872 10.0394 18.6258 10.0658C18.3796 10.0922 18.1326 10.0239 17.9348 9.87481L17.8256 9.77991L13.975 5.92801V15.925C13.975 16.1606 13.8897 16.3883 13.7349 16.5658C13.58 16.7434 13.3661 16.8589 13.1326 16.8909L13 16.9C12.7644 16.9 12.5368 16.8147 12.3592 16.6598C12.1817 16.505 12.0662 16.291 12.0341 16.0576L12.025 15.925V5.92801L8.17445 9.77991C8.00929 9.94537 7.79017 10.046 7.55704 10.0634C7.3239 10.0808 7.09226 10.0139 6.90435 9.87481L6.79515 9.77991C6.62969 9.61475 6.52908 9.39563 6.51166 9.1625C6.49424 8.92936 6.56117 8.69773 6.70025 8.50981L6.79515 8.40061Z" />
    </svg>
  </SVGUniqueID>
);
