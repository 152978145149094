import colors from './colors';
// import typography from './typography'
// import breakpoints from './breakpoints'
// import media from './media'
// import keyframes from './keyframes'
// import animation from './animation'


export default {
  colors,
  // typography,
  // breakpoints,
  // media,
  // keyframes,
  // animation,
}
