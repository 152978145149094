import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

const VkIcon = () => (
  <SVGUniqueID>
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8932 13.2398C4.05996 13.2398 0.162399 8.55528 0 0.760147H3.42286C3.53529 6.48157 6.05871 8.90506 8.05747 9.40474V0.760147H11.2805V5.69456C13.2542 5.4822 15.3279 3.2336 16.0275 0.760147H19.2505C18.9869 2.04295 18.4614 3.25756 17.7069 4.32801C16.9524 5.39845 15.9852 6.3017 14.8657 6.98126C16.1153 7.60219 17.2191 8.48107 18.1041 9.55989C18.9891 10.6387 19.6353 11.893 20 13.2398H16.4522C16.1248 12.07 15.4594 11.0227 14.5394 10.2294C13.6194 9.43607 12.4858 8.93192 11.2805 8.78014V13.2398H10.8932Z" fill="white"/>
    </svg>
  </SVGUniqueID>
);

export default VkIcon;
