import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.97015C0 15.4627 4.52381 20 10 20C15.4762 20 20 15.4627 20 9.97015C20 4.47761 15.4762 0 10 0C4.52381 0 0 4.47761 0 9.97015ZM13.6905 14.6269C11.369 13.194 8.39286 12.8358 4.94048 13.6119C4.10714 13.7313 3.9881 12.5373 4.70238 12.4179C8.5119 11.5224 11.7262 11.9403 14.3452 13.5522C15 13.9701 14.3452 14.9851 13.6905 14.6269ZM14.7024 11.9403C12.0238 10.2687 7.91667 9.79104 4.7619 10.7463C3.75 11.0448 3.39286 9.49254 4.34524 9.25373C7.97619 8.1791 12.4405 8.71642 15.5357 10.6269C16.369 11.1642 15.5357 12.4776 14.7024 11.9403ZM4.28571 7.70149C3.27381 8.0597 2.61905 6.32836 3.75 5.91045C7.2619 4.83582 13.2143 5.01493 16.9048 7.22388C17.9167 7.76119 16.9643 9.43284 15.8929 8.83582C12.6786 6.92537 7.32143 6.74627 4.28571 7.70149Z" fill="white"/>
    </svg>
  </SVGUniqueID>
);
