import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';

export default () => (
  <SVGUniqueID>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4693 6.8179C17.3498 6.90864 15.2532 8.06225 15.2532 10.6286C15.2532 13.5969 17.9204 14.6468 18 14.6727C17.9868 14.7374 17.5754 16.1114 16.5934 17.5113C15.7177 18.7427 14.802 19.974 13.4087 19.974C12.0155 19.974 11.6571 19.1834 10.0515 19.1834C8.4857 19.1834 7.92835 20 6.65445 20C5.38055 20 4.49146 18.8593 3.46977 17.4595C2.28877 15.8133 1.33337 13.2598 1.33337 10.836C1.33337 6.94741 3.921 4.88642 6.46867 4.88642C7.82222 4.88642 8.95004 5.7549 9.7993 5.7549C10.6087 5.7549 11.8694 4.83459 13.4086 4.83459C13.9926 4.83471 16.0893 4.88667 17.4693 6.8179V6.8179ZM12.679 3.18863C13.3158 2.4498 13.7671 1.42583 13.7671 0.401857C13.7671 0.259287 13.7538 0.116717 13.7272 0C12.6922 0.0389056 11.4581 0.674072 10.715 1.51657C10.1312 2.16466 9.58705 3.18863 9.58705 4.22565C9.58705 4.38115 9.61364 4.53677 9.62688 4.5886C9.69317 4.60153 9.79943 4.61458 9.90556 4.61458C10.8345 4.61446 12.0021 4.00527 12.679 3.18863V3.18863Z" fill="white"/>
    </svg>
  </SVGUniqueID>
);
